import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <p>The page you are looking for could not be found...</p>
    <p>
      Try going back to the <Link to="/">home page</Link>.
    </p>
  </Layout>
);

export default NotFoundPage;
